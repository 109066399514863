import {
  ChevronEndIcon,
  ChevronStartIcon,
} from '@fluentui/react-icons-northstar'
import { Button, Flex } from '@fluentui/react-northstar'
import React, { useEffect } from 'react'

interface FaqPaginationProps {
  pageIndex: number
  pageLength: number
  canPreviousPage: boolean
  canNextPage: boolean
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void
  onClickFirst: () => void
  onClickPrevious: () => void
  onClickNext: () => void
  onClickLast: () => void
}

const FaqPagination: React.FC<FaqPaginationProps> = ({
  pageIndex,
  pageLength,
  canPreviousPage,
  canNextPage,
  gotoPage,
  onClickFirst,
  onClickPrevious,
  onClickNext,
  onClickLast,
}) => {
  // フィルタ条件の変更などにより、表示中のページ番号がページ総数を超えた場合は最終ページを表示
  useEffect(() => {
    if (pageIndex + 1 > pageLength) {
      gotoPage(pageLength - 1)
    }
  }, [gotoPage, pageIndex, pageLength])

  return (
    <Flex gap="gap.medium" hAlign={'end'} vAlign={'center'}>
      <div>
        <Button
          text
          iconOnly
          icon={
            <div>
              <ChevronStartIcon styles={{ marginRight: '-20px' }} />
              <ChevronStartIcon />
            </div>
          }
          disabled={!canPreviousPage}
          onClick={onClickFirst}
        />
        <Button
          text
          iconOnly
          icon={<ChevronStartIcon />}
          disabled={!canPreviousPage}
          onClick={onClickPrevious}
        />
      </div>
      <div>
        ページ{' '}
        <strong>
          {pageIndex + 1} / {pageLength}
        </strong>{' '}
      </div>
      <div>
        <Button
          text
          iconOnly
          icon={<ChevronEndIcon />}
          disabled={!canNextPage}
          onClick={onClickNext}
        />
        <Button
          text
          iconOnly
          icon={
            <div>
              <ChevronEndIcon styles={{ marginRight: '-20px' }} />
              <ChevronEndIcon />
            </div>
          }
          disabled={!canNextPage}
          onClick={onClickLast}
        />
      </div>
    </Flex>
  )
}

export default React.memo(FaqPagination)
