import { ArrowLeftIcon, OpenOutsideIcon } from '@fluentui/react-icons-northstar'
import { Button } from '@fluentui/react-northstar'
import classNames from 'classnames'
import * as React from 'react'
import { useEffect, useRef } from 'react'

import styles from './style.module.css'

type Props = {
  isOpen: boolean
  onToggle: () => void
  documents: Array<{
    content: React.ReactNode
  }>
  fileName: string
  url: string
}

export const RequesterReferenceDocumentsPanelPresenter: React.FC<Props> = ({
  isOpen,
  onToggle,
  documents,
  fileName,
  url,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }, [isOpen, documents])

  return (
    <div
      className={classNames(styles.panelContainer, {
        [styles.panelContainerHidden]: !isOpen,
      })}
    >
      <div
        className={classNames(styles.panel, {
          [styles.panelClosed]: !isOpen,
        })}
      >
        <div className={styles.header}>
          <button className={styles.closeButton} onClick={onToggle}>
            <ArrowLeftIcon />
          </button>
          <span className={styles.fileName}>{fileName}</span>
        </div>
        <div className={styles.content} ref={contentRef}>
          {documents.map((document, index) => (
            <div key={index} className={styles.documentContainer}>
              <div className={styles.documentContent}>{document.content}</div>
              {index < documents.length - 1 && (
                <hr className={styles.divider} />
              )}
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.downloadLink}
          >
            <Button icon={<OpenOutsideIcon />} content="ファイルを開く" />
          </a>
        </div>
      </div>
    </div>
  )
}
