import * as React from 'react'

import styles from './style.module.css'

type Props = {
  renderNewTicket: () => React.ReactElement
  renderTicketList: () => React.ReactElement
  renderReferenceDocumentsPanel: () => React.ReactElement
}

export const RequesterChatPresenter: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <div className={styles.ticketList}>{props.renderTicketList()}</div>
        <div className={styles.newTicket}>{props.renderNewTicket()}</div>
      </div>
      <div className={styles.referenceDocumentsPanel}>
        {props.renderReferenceDocumentsPanel()}
      </div>
    </div>
  )
}

export default RequesterChatPresenter
