import { createApi } from '@reduxjs/toolkit/query/react'

import { Event, ResponseAttachment } from '../../proto/message_pb'
import {
  GetRagReferenceDocumentsRequest,
  GetRagReferenceDocumentsResponse,
} from '../../proto/rag_reference_pb'
import { RagReferenceAPI } from '../../proto/rag_reference_pb_service'
import * as RequesterImagePB from '../../proto/requester_image_pb'
import { RequesterImageAPI } from '../../proto/requester_image_pb_service'
import {
  CreateRequesterMessageRequest,
  RequesterMessage,
} from '../../proto/requester_message_pb'
import * as RequesterMessagePB from '../../proto/requester_message_pb'
import { RequesterMessageAPI } from '../../proto/requester_message_pb_service'
import { grpcBaseQuery } from './lib/grpcBaseQuery'
import { CreateMessageReplyArgs } from './types/CreateMessageReplyArgs'
import { CreateTicketRequestArgs } from './types/CreateTicketRequestArgs'
import { GetImageThumbnailArgs } from './types/GetImageThumbnailArgs'

export const deskApi = createApi({
  reducerPath: 'deskApi',
  baseQuery: grpcBaseQuery(),
  endpoints: (build) => ({
    /**
     * チケットをの作成をリクエストする
     */
    createRequesterTicket: build.mutation<
      RequesterMessage,
      CreateTicketRequestArgs
    >({
      query: (arg) => ({
        service: RequesterMessageAPI.CreateRequesterMessage,
        body: argsIntoCreateRequesterMessage(arg),
      }),
    }),
    /**
     * チケットの返信を送信する
     */
    createMessageReply: build.mutation<
      RequesterMessage,
      CreateMessageReplyArgs
    >({
      query: (args) => ({
        // チケットの作成と同じリクエストを使用する
        service: RequesterMessageAPI.CreateRequesterMessage,
        // チケットの作成と同じリクエストBodyに加えて、conversationId を設定する必要がある
        body: setConversationId(args.conversationId)(
          argsIntoCreateRequesterMessage(args)
        ),
      }),
      invalidatesTags: ['ConversationReplies'],
    }),
    getImageThumbnail: build.mutation<
      { c10000x10000: string; large: string; medium: string; small: string },
      GetImageThumbnailArgs
    >({
      query: (args) => ({
        service: RequesterImageAPI.RequesterImage,
        body: (() => {
          const req = new RequesterImagePB.RequesterImageRequest()
          req.setItemId(args.itemId)
          if ('groupId' in args) req.setGroupId(args.groupId)
          if ('driveId' in args) req.setDriveId(args.driveId)

          return req
        })(),
      }),
      transformResponse: (
        result: RequesterImagePB.RequesterImageResponse.AsObject
      ) => {
        return {
          c10000x10000: result.c10000x10000,
          large: result.large,
          medium: result.medium,
          small: result.small,
        }
      },
    }),
    getRagReferenceDocuments: build.query<
      GetRagReferenceDocumentsResponse.AsObject,
      { documentIds: string[]; accessIdentifierId: string }
    >({
      query: (args) => ({
        service: RagReferenceAPI.GetRagReferenceDocuments,
        body: (() => {
          const req = new GetRagReferenceDocumentsRequest()
          req.setDocumentIdsList(args.documentIds)
          req.setAccessIdentifierId(args.accessIdentifierId)
          return req
        })(),
      }),
    }),
  }),
  tagTypes: ['RequesterConversations', 'ConversationReplies'],
})

export const {
  useCreateMessageReplyMutation,
  useCreateRequesterTicketMutation,
  useGetRagReferenceDocumentsQuery,
} = deskApi

const argsIntoCreateRequesterMessage = <T extends CreateTicketRequestArgs>(
  args: T
): RequesterMessagePB.CreateRequesterMessageRequest => {
  const message = new Event.Message()
  message.setType('text')
  message.setText(args.plainText)

  const htmlAttachment = new ResponseAttachment()
  htmlAttachment.setType('text_html')
  htmlAttachment.setData(JSON.stringify({ content: args.html }))
  const event = new Event()
  event.setType('message')
  event.setAttachmentsList([
    htmlAttachment,
    ...args.attachments.map((a) => {
      const attachment = new ResponseAttachment()
      attachment.setType('onedrive')
      attachment.setData(JSON.stringify(a))
      return attachment
    }),
  ])
  event.setText(args.plainText)
  event.setMessage(message)

  const req = new CreateRequesterMessageRequest()
  req.setEvent(event)

  return req
}

const setConversationId =
  (conversationId: string) =>
  (
    message: RequesterMessagePB.CreateRequesterMessageRequest
  ): RequesterMessagePB.CreateRequesterMessageRequest => {
    const m = message.clone()
    m.setConversationId(conversationId)
    return m
  }
