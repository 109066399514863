import { Box, Flex, Loader } from '@fluentui/react-northstar'
import React, { useEffect, useMemo, useState } from 'react'
import { Column, useColumnOrder, usePagination, useTable } from 'react-table'

import { useListRecommendedFaqsQuery } from './api'
import FaqPagination from './FaqPagination'
import FilterSearchText from './FilterSearchText'
import styles from './styles.module.css'
import { RecommendedFaqEntity } from './types/RecommendedFaqEntity'

export interface Data {
  id: number
  displayName: string
  request: string
}

const fetchLimit = 10

const RecommendedFaqList: React.FC = () => {
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')

  const { data, isFetching } = useListRecommendedFaqsQuery({
    page,
    limit: fetchLimit,
    name: name || null,
  })

  const faqs: RecommendedFaqEntity[] = React.useMemo(() => {
    const faqs = data?.entities ?? []
    return faqs
  }, [data?.entities])

  const columns: Column<Data>[] = useMemo((): Column<Data>[] => {
    return [
      {
        Header: 'インテント表示名',
        id: 'インテント表示名',
        accessor: 'displayName',
        Cell: ({ cell: { value } }) => <th>{value}</th>,
      },
      {
        Header: 'リクエスト',
        id: 'リクエスト',
        accessor: 'request',
        Cell: ({ cell: { value } }) => <td>{value}</td>,
      },
    ]
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable<Data>(
    {
      columns,
      data: faqs.map((r) => ({
        id: r.id,
        displayName: r.displayName,
        request: r.request,
      })),
      initialState: {
        pageIndex: 0,
        pageSize: data?.pagination.totalPages,
      },
      manualPagination: true,
      pageCount: data?.pagination.totalPages,
    },
    usePagination,
    useColumnOrder
  )

  useEffect(() => {
    setPage(pageIndex + 1)
  }, [gotoPage, pageIndex, pageSize, pageCount])

  return (
    <Box>
      <Box>
        <FilterSearchText setName={setName} setPage={setPage} />
      </Box>
      {isFetching ? (
        <Loader size="small" />
      ) : (
        <>
          <Box className={styles.modal}>
            <table {...getTableProps()} className={styles.table}>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={row.original.id}
                      className={styles.tr}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <React.Fragment key={cell.column.id}>
                            {cell.column.id === 'インテント表示名' ? (
                              <th
                                {...cell.getCellProps()}
                                className={styles.th}
                              >
                                {cell.render('Cell')}
                              </th>
                            ) : (
                              <td
                                {...cell.getCellProps()}
                                className={styles.td}
                              >
                                {cell.render('Cell')}
                              </td>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Box>

          <Flex className={styles.pagination}>
            {faqs.length ? (
              <FaqPagination
                pageIndex={pageIndex}
                pageLength={pageOptions.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                onClickFirst={() => gotoPage(0)}
                onClickPrevious={() => previousPage()}
                onClickNext={() => nextPage()}
                onClickLast={() => gotoPage(pageCount - 1)}
              />
            ) : (
              <></>
            )}
          </Flex>
        </>
      )}
    </Box>
  )
}

export default React.memo(RecommendedFaqList)
