import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type State = {
  isOpen: boolean
  currentDocument: {
    documentIds: string[]
    accessIdentifierId: string
    fileName: string
    url: string
  } | null
}

const initialState: State = {
  isOpen: false,
  currentDocument: null,
}

const slice = createSlice({
  name: 'requesterReferenceDocumentsPanel',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    toggle: (state) => {
      state.isOpen = !state.isOpen
    },
    setCurrentDocument: (
      state,
      action: PayloadAction<{
        documentIds: string[]
        accessIdentifierId: string
        fileName: string
        url: string
      } | null>
    ) => {
      state.currentDocument = action.payload
    },
  },
})

export const { actions, reducer } = slice
