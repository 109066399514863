import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useGetRagReferenceDocumentsQuery } from '../deskApi'
import { RequesterReferenceDocumentsPanelPresenter } from './presenter'
import { currentDocumentSelector, isOpenSelector } from './selectors'
import { actions } from './slice'

export const RequesterReferenceDocumentsPanel: React.FC = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(isOpenSelector)
  const currentDocument = useSelector(currentDocumentSelector)

  const { data: documentData } = useGetRagReferenceDocumentsQuery(
    {
      documentIds: currentDocument?.documentIds ?? [],
      accessIdentifierId: currentDocument?.accessIdentifierId ?? '',
    },
    { skip: !currentDocument }
  )

  const handleToggle = React.useCallback(() => {
    dispatch(actions.toggle())
  }, [dispatch])

  const memoizedDocuments = React.useMemo(() => {
    return documentData?.documentsList?.length
      ? documentData.documentsList.map((document) => {
          const content = document.content || ''
          // 改行とその前後のスペースを一つの改行にまとめる
          // 例: "\n \n" や " \n\n " を "\n" にまとめる
          const singleLineContent = content.replace(/(\s*\n\s*)+/g, '\n')

          return {
            content: singleLineContent ? (
              <span>{singleLineContent}</span>
            ) : (
              <>
                参照ファイルを取得できませんでした。
                <br />
                ファイルが削除されたか、更新された可能性があります。
              </>
            ),
          }
        })
      : []
  }, [documentData?.documentsList])

  return (
    <RequesterReferenceDocumentsPanelPresenter
      isOpen={isOpen}
      onToggle={handleToggle}
      documents={memoizedDocuments}
      fileName={currentDocument?.fileName ?? 'ファイル名未設定'}
      url={currentDocument?.url ?? ''}
    />
  )
}

export default RequesterReferenceDocumentsPanel
