import { useMonaco } from '@monaco-editor/react'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { actions as panelActions } from '../../renewfeatures/RequesterReferenceDocumentsPanel/slice'
import styles from './styles.module.css'

type Props = {
  __dangerouslyHTML?: { __html: string }
}

const RequesterChatText: React.FC<Props> = (props) => {
  const monaco = useMonaco()
  const dispatch = useDispatch()
  const [html, setHtml] = React.useState(props.__dangerouslyHTML?.__html ?? '')
  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (target.tagName === 'A' && target.hasAttribute('data-reference-ids')) {
        e.preventDefault()
        const rawIds = target.getAttribute('data-reference-ids') || '[]'
        const documentIds = JSON.parse(rawIds.replace(/'/g, '"'))
        const accessIdentifierId =
          target.getAttribute('data-reference-key') || ''
        const fileName = target.textContent || 'ファイル名未設定'
        const url = target.getAttribute('href') || ''

        if (documentIds.length > 0 && accessIdentifierId) {
          dispatch(
            panelActions.setCurrentDocument({
              documentIds,
              accessIdentifierId,
              fileName,
              url,
            })
          )
          dispatch(panelActions.setOpen(true))
        }
      }
    }

    container.addEventListener('click', handleClick)
    return () => {
      container.removeEventListener('click', handleClick)
    }
  }, [dispatch])

  // コードブロックの色付け処理
  React.useEffect(() => {
    if (!props.__dangerouslyHTML) return
    setHtml(props.__dangerouslyHTML.__html)
    if (!monaco) return

    const parser = new DOMParser()
    const docEl = parser.parseFromString(
      props.__dangerouslyHTML.__html,
      'text/html'
    )
    const codeblockEl = docEl.querySelector('div.codeblock')
    if (!codeblockEl) return
    const preEl = codeblockEl.querySelector('pre')
    if (!preEl) return
    const language = preEl.getAttribute('data-language')
    monaco.editor
      .colorize(preEl.innerText, language ?? 'plaintext', {})
      .then((colorized) => {
        preEl.innerHTML = colorized
        setHtml(docEl.body.innerHTML)
      })
  }, [monaco, props.__dangerouslyHTML])

  return (
    <div
      ref={containerRef}
      className={styles.container}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default RequesterChatText
